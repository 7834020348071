import clsx from 'clsx'
import React, { useEffect } from 'react'
import { IfHydrated } from '../../libs/next/hydratation'
import type { BannerResult } from '../data/fragments/BannerFragment'
import { dispatchGtmEvent } from '../utils/dispatchGtmEvent'
import style from './Banner.module.sass'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'

export function Banner(props: {
	banner: BannerResult
	center?: boolean
	gtmPosition?: string
	onCLick?: () => void
}) {
	const { banner } = props

	const content = (
		<>
			{banner.imageMobile && (
				<div className={style.mobile}>
					<ContemberImage image={banner.imageMobile} />
				</div>
			)}
			{banner.image && (
				<div className={style.desktop}>
					<ContemberImage image={banner.image} />
				</div>
			)}
		</>
	)

	useEffect(() => {
		dispatchGtmEvent('ad_placed', { id: `ad-${banner.id}` })
	}, [banner.id])

	return (
		<div
			className={clsx(style.wrapper, props.center && style.center)}
			data-banner-id={banner.id}
			data-track-ad={JSON.stringify({
				ad: {
					id: banner.id,
					type: banner.promo ? 'promoBanner' : 'banner',
					position: props.gtmPosition,
				},
			})}
			id={`ad-${banner.id}`}>
			{banner.link ? (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<Link
					onClick={() => {
						if (window.dataLayer) {
							const isInstagramLink = props.banner.link?.externalLink?.includes('instagram')

							window.dataLayer.push({
								clickTo: `${
									isInstagramLink
										? 'banner - no more fomo'
										: `${props.gtmPosition ?? banner.title?.toLowerCase()}-banner`
								}`,
								event: 'clickTo_content',
								link: {
									type: 'banner',
									text: isInstagramLink ? 'no more fomo' : banner.title?.toLowerCase(),
									url:
										banner.link?.type === 'external'
											? banner.link.externalLink
											: banner.link?.internalLink?.url,
								},
							})
						}
					}}
					link={banner.link}
					ariaLabel="Reklamní banner">
					{content}
				</Link>
			) : (
				content
			)}
		</div>
	)
}

function InternalRandomBanner(props: {
	banners: BannerResult[]
	center?: boolean
	gtmPosition?: string
}) {
	const { banners } = props

	const banner = React.useMemo(() => {
		if (banners.length > 0) {
			return banners[Math.floor(Math.random() * banners.length)]
		}
	}, [banners])

	if (!banner) {
		return null
	}

	return <Banner banner={banner} center={props.center} gtmPosition={props.gtmPosition} />
}

export function RandomBanner(props: React.ComponentProps<typeof InternalRandomBanner>) {
	return <IfHydrated yes={() => <InternalRandomBanner {...props} />} />
}

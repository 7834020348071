import clsx from 'clsx'
import style from './MinAspectRatio.module.sass'

export type MinAspectRatioProps = {
	className?: string
	style?: React.CSSProperties
	children: React.ReactNode
	ratioEl?: {
		style?: React.CSSProperties
		className?: string
	}
} & ({ ratio: number } | { cssVariable: `--${string}`; ratio?: number })

export function MinAspectRatio(props: MinAspectRatioProps) {
	const placeholderStyle: React.CSSProperties & CSSCustomProperties = {
		paddingTop:
			'cssVariable' in props
				? `var(${props.cssVariable}, ${100 / (props.ratio ?? 1)}%)`
				: `${100 / props.ratio}%`,
	}

	return (
		<div className={clsx(style.Wrapper, props.className)} style={props.style}>
			<div className={style.Main}>
				<div className={style.Content}>{props.children}</div>
			</div>
			<div className={clsx(style.Ratio, props.ratioEl?.className)} style={props.ratioEl?.style}>
				<div className={style.Placeholder} style={placeholderStyle}></div>
			</div>
		</div>
	)
}

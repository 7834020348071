/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import React from 'react'
import { dump } from '../utils/dump'
import $Dump from './Dump.module.sass'

const negate = (val: boolean) => !val

export const Dump = React.memo(function Dump({ data, max }: { data?: any; max?: boolean }) {
	const [maximized, setMaximized] = React.useState(max ?? false)

	const handleClick = React.useCallback(() => {
		setMaximized(negate)
	}, [])

	const content = (
		<pre className={$Dump.Pre}>
			<span>{dump(data)}</span>
			<span className={$Dump.Corner}>
				<button type="button" className={$Dump.MaximizeButton} onClick={handleClick}>
					{maximized ? '-' : '+'}
				</button>
			</span>
		</pre>
	)

	return (
		<>
			<div className={clsx($Dump.Dump, maximized && $Dump.faded)}>{content}</div>
			{maximized && <div className={clsx($Dump.Dump, $Dump.maximized)}>{content}</div>}
		</>
	)
})

import React from 'react'
import { filterNonEmpty } from '../../libs/utils/filterNonEmpty'
import { Tile } from '../components/Tile'
import type { ArticleResult } from '../data/fragments/ArticleFragment'
import type { TagResult } from '../data/fragments/TagFragment'

export function normalizeTags(tags: { tag?: TagResult }[]): TagResult[] {
	return tags.map((tag) => tag.tag).filter(filterNonEmpty)
}

export function useTileGridItems(
	articles: Array<ArticleResult & { htmlId?: string }>,
	options: { authors?: boolean; tags?: boolean; gtmTileList?: string } = {},
	highlighted?: boolean,
	hasDate?: boolean
) {
	const authors = options.authors ?? true
	const tags = options.tags ?? true
	const gtmTileList = options.gtmTileList ?? 'seznam-beze-jmena'

	return React.useMemo(() => {
		const gridItems = articles.map((article, index) => {
			const image = article.mainImage
			const portrait = (image?.height ?? 1) / (image?.width ?? 1) > 1.1

			return (
				<Tile
					title={article.details?.plainTitle ?? article.title}
					image={image}
					hasDate={hasDate}
					highlighted={highlighted}
					author={authors ? article.authors[0] : undefined}
					imageAspectRatio={portrait ? 'portrait' : 'square'}
					url={article.url}
					stickers={article.stickers}
					key={article.id}
					tags={tags ? normalizeTags(article.tags) : undefined}
					attrs={{
						'data-is-story': article.isStory ? 'y' : 'n',
						'data-is-tip-of-week': article.isTipOfWeek ? 'y' : 'n',
						'data-is-trend': article.isTrend ? 'y' : 'n',
					}}
					promoPartner={article.promoPartner}
					gtm={{
						id: article.id,
						list: gtmTileList,
						position: index + 1,
						author: article.authors[0].name,
					}}
					publishAt={article.publishAt}
					htmlId={article.htmlId}
				/>
			)
		})

		return gridItems
	}, [articles, highlighted, authors, tags, gtmTileList])
}

import clsx from 'clsx'
import React from 'react'
import { ArticleHeaderView } from '../../generated/content'
import type { ImageResult } from '../data/fragments/ImageFragment'
import { ContemberImage } from './ContemberImage'
import style from './HeroImages.module.sass'
import { ImageCaption } from './ImageCaption'

const width1 = 160
const width2 = 100

const imageSizes = {
	secondaryImage1: `(min-width: 1320px) ${width1}px, (min-width: 700px) ${
		(100 * width1) / 1320
	}vw, ${(100 * width1) / 700}vw`,
	secondaryImage2: `(min-width: 1320px) ${width2}px, (min-width: 700px) ${
		(100 * width2) / 1320
	}vw, ${(100 * width2) / 700}vw`,
	mainImageFull: `100vw`,
	mainImageSplit: `(min-width: 1320px) 560px, (min-width: 700px) 50vw, 100vw`,
}

export type HeroImagesProps = {
	mainImage: ImageResult
	secondaryImage1?: ImageResult
	secondaryImage2?: ImageResult
	headerView?: string
	isHovered: boolean
}

export const HeroImages: React.FunctionComponent<HeroImagesProps> = ({
	mainImage,
	secondaryImage1,
	secondaryImage2,
	headerView,
	isHovered,
}) => {
	return (
		<div
			className={clsx(
				style.wrapper,
				isHovered && style.is_hovered,
				style[`view_${headerView}`],
				secondaryImage1 && style.view_offsetLeft,
				secondaryImage2 && style.view_offsetRight
			)}>
			{secondaryImage1 && headerView === ArticleHeaderView.split && (
				<div className={style.secondaryImage1}>
					<div className={style.secondaryImage1In}>
						<ContemberImage
							image={secondaryImage1}
							layout="fill"
							objectFit="cover"
							priority
							sizes={imageSizes.secondaryImage1}
						/>
					</div>
				</div>
			)}
			<figure>
				<div className={style.mainImage}>
					<div className={style.mainImageIn}>
						<ContemberImage
							image={mainImage}
							layout="fill"
							objectFit="cover"
							sizes={
								headerView === ArticleHeaderView.fullImage
									? imageSizes.mainImageFull
									: imageSizes.mainImageSplit
							}
						/>
					</div>
				</div>
				{mainImage.description && (
					<ImageCaption className={style.mainImageCaption} caption={mainImage.description} />
				)}
			</figure>
			{secondaryImage2 && headerView === 'split' && (
				<div className={style.secondaryImage2}>
					<div className={style.secondaryImage2In}>
						<ContemberImage
							image={secondaryImage2}
							layout="fill"
							objectFit="cover"
							sizes={imageSizes.secondaryImage2}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

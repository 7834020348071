import clsx from 'clsx'
import React from 'react'
import { ArticleHeaderView } from '../../generated/content'
import type { PerexContentResult } from '../data/fragments/PerexContentFragment'
import style from './HeroContent.module.sass'
import { MaybeRichText } from './RichText/MaybeRichText'
import { RichTextLite } from './RichText/RichText'
import { TagGrid, TagGridProps } from './TagGrid'
import type { AuthorResult } from '../data/fragments/AuthorFragment'
import { AuthorBadge } from './AuthorBadge'
import { FormattedDate } from './FormattedDate'

export type HeroContentProps = {
	tags?: TagGridProps
	title: string
	perex?: PerexContentResult
	authors?: AuthorResult[]
	publishAt?: string
	headerView?: ArticleHeaderView
}

export const HeroContent: React.FunctionComponent<HeroContentProps> = ({
	tags,
	title,
	perex,
	authors,
	publishAt,
	headerView,
}) => {
	const containsLongWord = React.useMemo(() => {
		const stringArr = title.split(' ')

		return stringArr.some((string) => string.length > 12)
	}, [title])
	return (
		<div
			className={clsx(
				style.wrapper,
				headerView === ArticleHeaderView.split ? style.view_split : style.view_fullImage
			)}>
			{/* <Icon name="star" /> */}
			{tags?.items && tags?.items.length > 0 && (
				<div className={style.tags}>
					<TagGrid {...tags} align="center" size="large" bigSidePadding />
				</div>
			)}
			<h1 className={clsx(style.title, containsLongWord && style.view_containsLongWord)}>
				<MaybeRichText>{title}</MaybeRichText>
			</h1>
			{perex && (
				<div className={style.perex}>
					<RichTextLite blocks={perex.parts} />
				</div>
			)}
			{authors && (
				<div className={style.authors}>
					{authors.map((author, i) => (
						<div className={style.author} key={author.id}>
							<AuthorBadge
								prefix={
									i === 0 ? (
										<>
											{publishAt && <FormattedDate date={publishAt} />}
											{` by `}
										</>
									) : (
										''
									)
								}
								{...author}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

import type { FunctionComponent } from 'react'
import style from './Spacer.module.sass'

export interface SpacerProps {
	initial?: number
	// Keep in sync with Spacer.module.sass
	break360?: number
	break480?: number
	break560?: number
	break700?: number
	break1000?: number
	break1400?: number
}

const getBreakCustomProperties = (props: SpacerProps): React.CSSProperties => {
	const breaks = [
		{ name: 'initial', value: props.initial },
		{ name: '360', value: props.break360 },
		{ name: '480', value: props.break480 },
		{ name: '560', value: props.break560 },
		{ name: '700', value: props.break700 },
		{ name: '1000', value: props.break1000 },
		{ name: '1400', value: props.break1400 },
	]

	for (let i = 1; i < breaks.length; i++) {
		if (typeof breaks[i].value === 'undefined' && typeof breaks[i - 1].value !== 'undefined') {
			breaks[i].value = breaks[i - 1].value
		}
	}

	const styles: React.CSSProperties & CSSCustomProperties = {}
	breaks.forEach((item) => {
		styles[`--spacer-break-${item.name}`] = item.value
	})

	return styles
}

export const Spacer: FunctionComponent<SpacerProps> = (props) => {
	return <div className={style.Wrapper} style={getBreakCustomProperties(props)}></div>
}
